// SPDX-FileCopyrightText: OpenTalk GmbH <mail@opentalk.eu>
//
// SPDX-License-Identifier: EUPL-1.2

export enum ModerationTabKey {
  Home = 'tab-home',
  MuteUsers = 'tab-mute-users',
  ResetHandraises = 'tab-reset-handraises',
  Breakout = 'tab-breakout-rooms',
  Whiteboard = 'tab-whiteboard',
  Polls = 'tab-polls',
  LegalVote = 'tab-voting',
  Timer = 'tab-timer',
  CoffeeBreak = 'tab-coffee-break',
  MeetingNotes = 'tab-meeting-notes',
  AddUser = 'tab-add-user',
  TalkingStick = 'tab-talking-stick',
  Debriefing = 'tab-debriefing',
  Wollknaul = 'tab-wollknaul',
  SpeakerQueue = 'tab-speaker-queue',
  WheelOfNames = 'tab-wheel-of-names',
  Divider = 'tab-divider',
  WaitingRoom = 'tab-waiting-room',
  PollsAndLegalVote = 'tab-polls-voting',
  SupportMenu = 'tab-support-menu',
}
