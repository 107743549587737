// SPDX-FileCopyrightText: OpenTalk GmbH <mail@opentalk.eu>
//
// SPDX-License-Identifier: EUPL-1.2

export const MY_MEETING_MENU_BUTTON_ID = 'my-meeting-menu-button';
export const CHAT_INPUT_ID = 'chat-input';

export enum ToolbarButtonIds {
  Handraise = 'toolbar-handraise',
  ShareScreen = 'toolbar-share-screen',
  Audio = 'toolbar-audio',
  Video = 'toolbar-video',
  More = 'toolbar-more',
  EndCall = 'toolbar-endcall',
}

export const LIVEKIT_SCREEN_SHARE_PERMISSION_NUMBER = 3;
export const LIVEKIT_AUDIO_PERMISSION_NUMBER = 2;

export const MAX_GRID_TILES_DESKTOP = 9;
export const MAX_GRID_TILES_MOBILE = 9;

export const HOTKEY_MICROPHONE = 'm';
export const HOTKEY_WHISPERGROUP = 'w';
export const HOTKEY_VIDEO = 'v';
export const HOTKEY_FULLSCREEN = 'f';
export const HOTKEY_PUSH_TO_TALK = ' ';
export const HOTKEY_NEXT_SPEAKER = 'n';
export const HOTKEYS = [
  HOTKEY_VIDEO,
  HOTKEY_MICROPHONE,
  HOTKEY_FULLSCREEN,
  HOTKEY_PUSH_TO_TALK,
  HOTKEY_NEXT_SPEAKER,
  HOTKEY_WHISPERGROUP,
];
